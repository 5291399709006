// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualites-js": () => import("./../../../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-articles-contentful-article-slug-js": () => import("./../../../src/pages/articles/{contentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-articles-contentful-article-slug-js" */),
  "component---src-pages-contentful-page-slug-js": () => import("./../../../src/pages/{contentfulPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-page-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numeros-contentful-issue-slug-js": () => import("./../../../src/pages/numeros/{contentfulIssue.slug}.js" /* webpackChunkName: "component---src-pages-numeros-contentful-issue-slug-js" */),
  "component---src-pages-numeros-js": () => import("./../../../src/pages/numeros.js" /* webpackChunkName: "component---src-pages-numeros-js" */)
}

